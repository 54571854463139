<script lang="ts" setup>
import SpecialistMenu from '~/components/specialist/SpecialistMenu.vue'
import { useAuthStore } from '~/store/auth'
import { useUserStore } from '~/store/user'

const authStore = useAuthStore()
const userStore = useUserStore()

useAsyncData(async (): Promise<void> => {
  initializeStores()
})

function initializeStores (): void {
  authStore.initializeStore({
    guestId: useCookie('guestId').value || null,
    token: useCookie('token').value || null,
    userId: useCookie('userId').value || null
  })
  userStore.initializeStore()
}
</script>

<template>
  <div>
    <SpecialistMenu />
    <Container class="mb-10 md:mb-[60px]">
      <slot />
    </Container>
    <Footer />
  </div>
</template>
