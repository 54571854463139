<script lang="ts" setup>
</script>

<template>
  <!-- TODO: change opacity -->
  <div class="block opacity-0 md:hidden">
    <SvgIcon class="!size-6" name="outlined/burger" />
    <nav class="hidden">
      <!-- TODO: ... -->
    </nav>
  </div>
</template>
